<template>
    <div class="HomeIndex">
      <!-- <p class="pageTop"></p> -->
      <div class="PageCommon">
        <div class="" v-if="mobileFlag">
          <el-tabs v-model="activeHome" @tab-click="handleClick">
            <el-tab-pane v-if="isSignal" :label="$t('home_titlesymbol')" name="signal"></el-tab-pane>
            <el-tab-pane v-if="isFollower" :label="$t('home_titlefollow')" name="follower"></el-tab-pane>
        </el-tabs>
        </div>
        <div v-if="isSignalAll">
          <h3 class="pageTitle" v-if="!mobileFlag">
            {{ $t("home_titlesymbol") }}

          </h3>
          <p class="pageSubtitle">
            <b class="pageSubIcon"></b>
            {{ $t("home_titlesymbolSubtitle1") }}
          </p>

          <ul class="homeTotalUl">
            <li @click="toFollowAll(0)">
              <span class="homeTotalNum homeNumColorblue">
                {{ signalData.waitAgree }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus1')}}
              </span>
            </li>
            <li @click="toFollowAll(1)">
              <span class="homeTotalNum homeNumColorgreen">
                {{ signalData.following }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus2')}}
              </span>
            </li>
            <li @click="toFollowAll(2)">
              <span class="homeTotalNum homeNumColorgrey">
                {{ signalData.refuse }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus3')}}
              </span>
            </li>
            <li @click="toFollowAll(4)">
              <span class="homeTotalNum homeNumColorpurple">
                {{ signalData.waitActive }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus4')}}
              </span>
            </li>
          </ul>

          <p class="pageSubtitle">
            <b class="pageSubIcon"></b>
            {{ $t("home_titlesymbolSubtitle2") }}
            <span class="homeOpenClose" v-if="!mobileFlag" @click="tabletoggle()">
              {{ isOpen? $t('home_close') :  $t('home_open')}}
              <i  :class="[isOpen?'tableOpenIcon':'','el-icon-arrow-down','transition2s']"></i>
            </span>
          </p>
          <collapse-transition>
            <div class="tableDiv" v-if="isOpen && !mobileFlag">
            <el-table :data="signalData.signalStatistics" border style="width: 100%" ref="dataTable">

              <el-table-column :width="flexLabelWidth($t('MyFollowers_SignalSource'))" prop="portfolioName"
                :label="$t('MyFollowers_SignalSource')">
                <template slot-scope="scope">
                    <div  class="mt4Click" @click="toAccount(scope.row)">
                      {{ scope.row.portfolioName }}
                    </div>
                  </template>
              </el-table-column>
              <el-table-column :width="flexLabelWidth($t('home_signalAccountNum'))" prop="signalCount"
                :label="$t('home_signalAccountNum')">
              </el-table-column>
              <el-table-column prop="balance" :label="$t('home_signalAccountEquity')">
                <template slot-scope="scope">
                  <div>
                    {{ formatNumberFractionDigits(scope.row.singalTotalEquity) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="equity" :label="$t('home_signalAccountPL')">
                <template slot-scope="scope">
                  <div :class="scope.row.signalTotalProfit > 0?'greenNum':'redNum'">
                    {{  formatNumberFractionDigits(scope.row.signalTotalProfit)  }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="margin" :width="flexLabelWidth($t('home_followAccountEquity'))"
                :label="$t('home_followAccountEquity')">
                <template slot-scope="scope">
                  <div>
                    {{  formatNumberFractionDigits(scope.row.followTotalEquity)  }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="flexLabelWidth($t('home_followAccountPL'))" prop="freeMargin"
                :label="$t('home_followAccountPL')">
                <template slot-scope="scope">
                  <div :class="scope.row.followTotalProfit > 0?'greenNum':'redNum'">
                    {{ scope.row.followTotalProfit ?formatNumberFractionDigits(scope.row.followTotalProfit) : 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="flexLabelWidth($t('home_signaloutline'))" prop="marginLevel"
                :label="$t('home_signaloutline')">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.signalOfflineCount ? scope.row.signalOfflineCount : 0
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="credit" :label="$t('home_followoutline')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.followOfflineCount ? scope.row.followOfflineCount : 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="credit" :label="$t('home_lostnum')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.lostOrderCount ? scope.row.lostOrderCount : 0 }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </collapse-transition>
          <div class="mobileTableList" v-if="mobileFlag">
            <el-collapse accordion>
              <el-collapse-item v-for="(item,index) in signalData.signalStatistics" :key="index">
                <template slot="title">
                  <div class="mobileTableCard" @click.stop="showCollapse()">
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_SignalSource') }}
                      </p>
                      <p class="mobileTableContent mobileUnderline" @click="toAccount(item)">
                        {{ item.portfolioName }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_signalAccountNum') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.signalCount ? item.signalCount : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_signalAccountEquity') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.singalTotalEquity ?formatNumberFractionDigits(item.singalTotalEquity)  : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_signalAccountPL') }}
                      </p>
                      <p  :class="[item.signalTotalProfit > 0?'greenNum':'redNum','mobileTableContent']">
                        {{  item.signalTotalProfit ? formatNumberFractionDigits(item.signalTotalProfit) : 0 }}
                      </p>
                    </div>
                  </div>

                </template>
                <div class="mobileTableCard" >
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_followAccountEquity') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{ item.followTotalEquity ?formatNumberFractionDigits(item.followTotalEquity)  : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_followAccountPL') }}
                      </p>
                      <p :class="[item.followTotalProfit > 0?'greenNum':'redNum','mobileTableContent']" >
                        {{  item.followTotalProfit ? formatNumberFractionDigits(item.followTotalProfit) : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_signaloutline') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.signalOfflineCount ? item.signalOfflineCount : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_followoutline') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{  item.followOfflineCount ? item.followOfflineCount : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('home_lostnum') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{  item.lostOrderCount ? item.lostOrderCount : 0 }}
                      </p>
                    </div>
                  </div>
              </el-collapse-item>

            </el-collapse>
            <!-- <div class="mobileTableCard" v-for="item in signalData.signalStatistics">
              <div class="mobileTableItem">
                <p class="mobileTableTitle" >
                  {{ $t('MyFollowers_SignalSource') }}
                </p>
                <p class="mobileTableContent mobileUnderline" @click="toAccount(item)">
                  {{ item.portfolioName }}
                </p>
              </div>
              <div class="mobileTableItem">
                <p class="mobileTableTitle" >
                  {{ $t('home_signalAccountNum') }}
                </p>
                <p class="mobileTableContent" >
                  {{  item.signalCount ? item.signalCount : 0 }}
                </p>
              </div>
              <div class="mobileTableItem">
                <p class="mobileTableTitle" >
                  {{ $t('home_signalAccountEquity') }}
                </p>
                <p class="mobileTableContent" >
                  {{  item.singalTotalEquity ? item.singalTotalEquity : 0 }}
                </p>
              </div>
              <div class="mobileTableItem">
                <p class="mobileTableTitle" >
                  {{ $t('home_signalAccountPL') }}
                </p>
                <p  :class="[item.signalTotalProfit > 0?'greenNum':'redNum','mobileTableContent']">
                  {{  item.signalTotalProfit ? item.signalTotalProfit : 0 }}
                </p>
              </div>
            </div> -->
          </div>
        </div>

        <div v-if="isFollowerAll">
          <h3 class="pageTitle pageTitleTop" v-if="!mobileFlag">
            {{ $t("home_titlefollow") }}

          </h3>
          <p class="pageSubtitle">
            <b class="pageSubIcon"></b>

            {{ $t("home_titlesymbolSubtitle1") }}
          </p>

          <ul class="homeTotalUl">
            <li  @click="toFollowList(0)">
              <span class="homeTotalNum homeNumColorblue">
                {{ followData.invited }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus5')}}
              </span>
            </li>
            <li  @click="toFollowList(1)">
              <span class="homeTotalNum homeNumColorgreen">
                {{ followData.beInvitedFollowing }}
              </span>
              <span class="homeTotalStatus">
                {{$t('home_titlesymbolstatus2')}}
              </span>
            </li>
            <li  @click="toFollowList(4)">
              <span class="homeTotalNum homeNumColorpurple">
                {{ followData.invitedWaitActive }}
              </span>
              <span class="homeTotalStatus">
                {{$t('MyFollowers_InvitationAccepted')}}
              </span>
            </li>
          </ul>
          <p class="pageSubtitle" v-if="!mobileFlag">
            <b class="pageSubIcon"></b>

            {{ $t("MyFollowers_BeingInvited") }}

          </p>
          <p class="pageSubtitle" v-if="mobileFlag && inviteTable.length !== 0">
            <b class="pageSubIcon"></b>

            {{ $t("MyFollowers_BeingInvited") }}

          </p>
          <div class="tableDiv" v-if="!mobileFlag">
            <el-table :data="inviteTable" border style="width: 100%" ref="dataTable" >

                  <el-table-column  prop="portfolioName"
                    :label="$t('MyFollowers_SignalSource')">
                  </el-table-column>
                  <el-table-column  prop="portfolioLoginId"
                    :label="$t('MyFollowers_SignalSourceId')">
                  </el-table-column>
                  <el-table-column width="100" prop="shareProfitPercent" :label="$t('paymentProfitSharingRatio')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.shareProfitPercent ? scope.row.shareProfitPercent+'%' : 0}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="100" prop="durationName" :label="$t('settlementCycle')">
                </el-table-column>
                  <el-table-column prop="percent" :label="$t('MyFollowers_CopyRatio')">
                    <template slot-scope="scope">
                      <div>
                        {{ scope.row.percent ? scope.row.percent+'%' : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="maxVolume" :label="$t('MyFollowers_MaxLot1')">
                    <template slot-scope="scope">
                      <div>
                        {{ scope.row.maxVolume ? (scope.row.maxVolume==0? $t('MyFollowers_volNotLimit') : scope.row.maxVolume): $t('MyFollowers_volNotLimit')}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="symbols" width="135"
                    :label="$t('MyFollowers_Instruments1')">
                    <template slot-scope="scope">
                      <div>
                        <span class="symbolsSpan" v-if="scope.row.forexSelect">
                          {{ $t("MyFollowers_Fx") }}
                        </span>
                        <span class="symbolsSpan" v-if="scope.row.metalsSelect">
                          {{ $t("MyFollowers_Metal") }}
                        </span>
                        <span class="symbolsSpan" v-if="scope.row.energySelect">
                          {{ $t("MyFollowers_Energy") }}
                        </span>
                        <span class="symbolsSpan" v-if="scope.row.indicesSelect">
                          {{ $t("MyFollowers_Indices") }}
                        </span>
                        <span class="symbolsSpan" v-if="scope.row.cryptoSelect">
                          {{ $t("MyFollowers_Crypto") }}
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="160" :label="$t('MyFollowers_AutomaticOrderReplenishment')">
                    <template slot-scope="scope">
                      <div>
                        <el-result v-if="scope.row.autoRecon" icon="success"></el-result>
                        <el-result v-if="!scope.row.autoRecon" icon="error"></el-result>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :width="flexLabelWidth($t('MyFollowers_ActivationStatus'))"
                    :label="$t('MyFollowers_ActivationStatus')">
                    <template slot-scope="scope">
                      <div>
                        <el-result v-if="scope.row.activation" icon="success"></el-result>
                        <el-result v-if="!scope.row.activation" icon="error"></el-result>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :fixed="inviteTable.length !== 0 ? 'right' : false" v-if="!mobileFlag" width="180"
                    :label="$t('MyFollowers_Operation')">
                    <template slot-scope="scope">
                      <!-- {{ scope.row.insTime | dateFilter }} -->
                      <div class="operateBtns">

                        <el-button v-if="scope.row.status == 0" @click="checkFollow(scope.row)" type="text" size="small"
                          v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                          {{ $t("MyFollowers_ViewInvitation") }}
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :fixed="inviteTable.length !== 0 ? 'right' : false" v-if="mobileFlag" width="80"
                    :label="$t('MyFollowers_Operation')">
                    <template slot-scope="scope">
                      <!-- {{ scope.row.insTime | dateFilter }} -->
                      <div class="operateBtns">
                        <el-popover placement="bottom-end" trigger="click">

                          <el-button v-if="scope.row.status == 0" @click="checkFollow(scope.row)" type="text" size="small"
                            v-loading.fullscreen.lock="fullscreenLoading">
                            <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                            {{ $t("MyFollowers_ViewInvitation") }}
                          </el-button>
                        </el-popover>

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
          </div>
          <div class="mobileTableList" v-if="mobileFlag">
            <el-collapse accordion>
              <el-collapse-item v-for="(item,index) in inviteTable" :key="index">
                <template slot="title">
                  <div class="mobileTableCard" @click.stop="showCollapse()">
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_SignalSource') }}
                      </p>
                      <p class="mobileTableContent">
                        {{ item.portfolioName }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_SignalSourceId') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.portfolioLoginId  }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('paymentProfitSharingRatio') }}
                      </p>
                      <p class="mobileTableContent" >
                          {{  item.shareProfitPercent ? item.shareProfitPercent+'%' : 0}} 
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('settlementCycle') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{ item.durationName  }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_CopyRatio') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.percent ? item.percent +'%' : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_MaxLot1') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{ item.maxVolume ? (item.maxVolume==0? $t('MyFollowers_volNotLimit') : item.maxVolume): $t('MyFollowers_volNotLimit')}}
                      </p>
                    </div>
                  </div>
                  <div class="mobileTableBtns" @click.stop="showCollapse()">
                    <el-button class="mobileBlueBtn" v-if="item.status == 0" @click="checkFollow(item)" type="text" size="small"
                      v-loading.fullscreen.lock="fullscreenLoading">
                      <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                      {{ $t("MyFollowers_ViewInvitation") }}
                    </el-button>
                  </div>
                </template>
                <div class="mobileTableCard" >
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_Instruments1') }}
                      </p>
                      <p class="mobileTableContent" >
                        <span class="symbolsSpan" v-if="item.forexSelect">
                          {{ $t("MyFollowers_Fx") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.metalsSelect">
                          {{ $t("MyFollowers_Metal") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.energySelect">
                          {{ $t("MyFollowers_Energy") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.indicesSelect">
                          {{ $t("MyFollowers_Indices") }}
                        </span>
                        <span class="symbolsSpan" v-if="item.cryptoSelect">
                          {{ $t("MyFollowers_Crypto") }}
                        </span>
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_AutomaticOrderReplenishment') }}
                      </p>
                      <p class="mobileTableContent" >
                        <el-result v-if="item.autoRecon" icon="success"></el-result>
                        <el-result v-if="!item.autoRecon" icon="error"></el-result>
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_ActivationStatus') }}
                      </p>
                      <p class="mobileTableContent" >
                        <el-result v-if="item.activation" icon="success"></el-result>
                        <el-result v-if="!item.activation" icon="error"></el-result>
                      </p>
                    </div>
                </div>

              </el-collapse-item>

            </el-collapse>
          </div>
          <p class="pageSubtitle">
            <b class="pageSubIcon"></b>

            {{ $t("home_titlesymbolSubtitle3") }}
            <span v-if="!mobileFlag" class="homeOpenClose" @click="tabletoggleFollow()">
              {{ isOpenFollow? $t('home_close') :  $t('home_open')}}
              <i  :class="[isOpenFollow?'tableOpenIcon':'','el-icon-arrow-down','transition2s']"></i>
            </span>
          </p>
          <collapse-transition>
            <div class="tableDiv" v-if="isOpenFollow">
            <el-table :data="followData.followStatistics" border style="width: 100%" ref="dataTable">

              <el-table-column   prop="account"
                :label="$t('MyFollowers_MT4Account')">
                <template slot-scope="scope">
                  <div v-if="scope.row.status == 1"  class="mt4Click" @click="toEcharts(scope.row.account)">
                    {{ scope.row.account }}
                  </div>
                  <div v-else >
                    {{ scope.row.account }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column  prop="portfolioName"
                :label="$t('MyFollowers_SignalSource')">
              </el-table-column>
              <el-table-column  prop="equity"
                :label="$t('MyFollowers_Equity')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.equity ? formatNumberFractionDigits(scope.row.equity) : 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="volume" :label="$t('MyFollowers_Lot')">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.volumes ? formatNumberFractionDigits(scope.row.volumes) : 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="profit" :label="$t('MyFollowers_PL')">
                <template slot-scope="scope">
                  <div :class="scope.row.profit > 0?'greenNum':'redNum'">
                    {{ scope.row.profit ? formatNumberFractionDigits(scope.row.profit) : 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('MyFollowers_Status')" >
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.status == 0" class="statusGreen">{{
                      $t("MyFollowers_BeingInvited")
                    }}</span>
                    <span v-if="scope.row.status == 1" class="statusYellow">{{
                      $t("MyFollowers_Copying")
                    }}</span>
                    <span v-if="scope.row.status == 2" class="statusRed">{{
                      $t("MyFollowers_InviteRejected")
                    }}</span>
                    <span v-if="scope.row.status == 3" class="statusRed">
                      {{ $t("MyFollowers_StopCopying1") }}
                    </span>
                    <span v-if="scope.row.status == 4" class="statusYellow">{{
                      $t("MyFollowers_InviteSuccessNoActive")
                    }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </collapse-transition>
          <div class="mobileTableList" v-if="mobileFlag">
            <el-collapse accordion>
              <el-collapse-item v-for="(item,index) in followData.followStatistics" :key="index">
                <template slot="title">
                  <div class="mobileTableCard" @click.stop="showCollapse()">
                    <div class="mobileTableItem mobileTableStatusTitle">
                      <div >
                        <span v-if="item.status == 0" class="statusGreen">{{
                            $t("MyFollowers_BeingInvited")
                          }}</span>
                          <span v-if="item.status == 1" class="statusYellow">{{
                            $t("MyFollowers_Copying")
                          }}</span>
                          <span v-if="item.status == 2" class="statusRed">{{
                            $t("MyFollowers_InviteRejected")
                          }}</span>
                          <span v-if="item.status == 3" class="statusRed">
                            {{ $t("MyFollowers_StopCopying1") }}
                          </span>
                          <span v-if="item.status == 4" class="statusYellow">{{
                            $t("MyFollowers_InviteSuccessNoActive")
                          }}</span>
                      </div>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_MT4Account') }}
                      </p>
                      <p class="mobileTableContent mobileUnderline" v-if="item.status == 1"  @click="toEcharts(item.account)">
                            {{ item.account }}
                      </p>
                      <p class="mobileTableContent" v-else >
                            {{ item.account }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_SignalSource') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.portfolioName  }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_Equity') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.equity ? formatNumberFractionDigits(item.equity) : 0 }}
                      </p>
                    </div>
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_Lot') }}
                      </p>
                      <p  class="mobileTableContent" >
                        {{  item.volumes ? formatNumberFractionDigits(item.volumes) : 0 }}
                      </p>
                    </div>
                  </div>

                </template>
                <div class="mobileTableCard" >
                    <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                        {{ $t('MyFollowers_PL') }}
                      </p>
                      <p :class="[item.profit > 0?'greenNum':'redNum','mobileTableContent']">
                        {{ item.profit ? formatNumberFractionDigits(item.profit) : 0 }}
                      </p>
                    </div>
                  </div>
              </el-collapse-item>

            </el-collapse>

          </div>
        </div>

      </div>
      <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
      @confirmPopupTips="confirmPopup"
      @cancelPopupTips="cancelPopup"
      @cancelClosePopupTips="cancelClosePopup"
      ref="popupTipsRef"
      :isShowClose="isShowClose"
      :confirmTxt="confirmTxt"
      :cancelTxt="cancelTxt"
      :content="$t(popupContent)" :btnType="btnType" />

      <el-drawer :title="followDrawerTitle" class="drawerCommon checkInviteDrawer" :append-to-body="true"
      @close="closeDrawer()" :close-on-press-escape="false" :visible.sync="checkInviteDrawer" direction="rtl">
      <div class="drawerContent">
        <el-form :model="followForm" :rules="followRule" ref="followForm" label-position="right" label-width="150px"
          class="drawerForm" :label-position="mobileFlag ? 'top' : 'left'">
          <!-- 跟单参数设置 -->
            <div v-show="checkInviteParameter">
              <h3 class="checkInviteDrawerTitle">
                <i></i> {{ $t("MyFollowers_ViewParametersOfBeingInvitedtoCopy") }}
              </h3>
              <el-form-item :label="$t('paymentProfitSharingRatio')">
                <el-input disabled v-model="followForm.shareProfitPercent"></el-input>
                <b class="formPercent">%</b>
              </el-form-item>
              <el-form-item :label="$t('settlementCycle')">
                <el-input disabled v-model="followForm.settlementPeriodTypeTitle"></el-input>
              </el-form-item>
              <el-form-item :label="$t('firstSettlementTime')">
                <el-input disabled v-model="followForm.currentSettlementEndTime"></el-input>
              </el-form-item>
              <el-form-item prop="following">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_CopyRatio") }} </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_CopyRatio'), $t('MyFollowers_copyPercentTxt'),$t('MyFollowers_copyPercentTxt1'))">
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
    
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>
                      <div>
                      <div>
                        {{ $t('MyFollowers_copyPercentTxt') }}
                      </div><br />
                      <div>
                        {{ $t('MyFollowers_copyPercentTxt1') }}
                      </div>
                    </div>
                    </div>
                  </span>
                </span>
                <el-input disabled v-model.number="followForm.following" autocomplete="off"></el-input>
                <b class="formPercent">%</b>
              </el-form-item>
              <el-form-item prop="maxVolume">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_MaxLot") }} </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_MaxLot'), $t('MyFollowers_maxVolumnTxt'))">
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>
                      <div>
                        <div>
                          {{ $t('MyFollowers_maxVolumnTxt') }}
                        </div><br />
                        <div>
                          {{ $t('MyFollowers_maxVolumnTxt1') }}
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                <el-input-number disabled class="creaseInput" controls-position="right" v-model.number="followForm.maxVolume" :placeholder="$t('nolimit')"
                  autocomplete="off"></el-input-number>
                  <b class="formLots">lots</b>
    
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_Instruments')">
                <el-input disabled v-model="followForm.symbols"></el-input>
              </el-form-item>
              <el-form-item prop="autoRecon">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_WhetherToAutomaticallyReplenishOrders") }}
                    </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_WhetherToAutomaticallyReplenishOrders'), $t('MyFollowers_isAutoTxt'))">
    
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_isAutoTxt") }}
                    </div>
                  </span>
                </span>
                <el-radio-group v-model="followForm.autoRecon" disabled>
                  <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
                  <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="activation" :label="$t('MyFollowers_ActivateAfterClientConsent')">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_ActivateAfterClientConsent") }}
                    </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_ActivateAfterClientConsent'), $t('MyFollowers_mainActiveTxt'))">
    
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_mainActiveTxt") }}
                    </div>
                  </span>
                </span>
                <el-radio-group v-model="followForm.activation" disabled>
                  <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
                  <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          <!-- 同意邀请后展示账号设置 -->
          <div v-show="checkInviteDetailAccount">
              <h3 class="checkInviteDrawerTitle">
                <i></i>{{ $t("MyFollowers_AcceptInvitation") }}
              </h3>
              <el-form-item :label="$t('brokerName')" prop="brokerName" class="toReg">
                <el-select :disabled="isFollowing" v-model="followForm.brokerName" 
                  @change="selectChange">
                  <el-option v-for="item in brokerList" :key="item.id" :label="item.brokerName" :value="item.id">
                  </el-option>
                </el-select>
                <p><span @click="toReg()">{{ $t('NoAccountRegister2')}}</span></p>
              </el-form-item>
              <el-form-item :label="$t('server')" prop="serverId">
                <!-- <el-input v-model="followForm.serverName" disabled></el-input> -->
                <el-select
                  v-model="followForm.serverId"
                  class="allLengthInput"
                  :disabled="isFollowing"
                >
                  <el-option
                    v-for="item in serverList"
                    :key="item.serverId"
                    :label="item.serverName"
                    :value="item.serverId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_ChooseMT4Account')" prop="account">
                <!-- <el-select :disabled="isFollowing" v-model="followForm.account" class="drawer_100Input"
                  @change="selectChange">
                  <el-option v-for="item in followForm.accountList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select> -->
                <el-input :disabled="isFollowing"  v-model="followForm.account" autocomplete="off" ></el-input>

              </el-form-item>
              <el-form-item v-if="checkRow.status == 0" :label="$t('MyFollowers_Mt4Password')" prop="cwPassword">
    
                <el-input type="password" v-model="followForm.cwPassword" autocomplete="off" show-password></el-input>
    
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_OneClickToClose')" v-if="checkRow.status == 1">
                <div class="drawerCard">
                  <el-form-item>
                    <div class="drawerCardItem">
                      <span class="drawerCard_Label drawerCard_LabelWidth">{{
                        $t("MyFollowers_ChooseOrder")
                      }}</span>
                      <el-select class="drawerCard_100Input" v-model="followForm.orderType">
                        <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                        <el-option :label="$t('MyFollowers_order_sell')" :value="2"></el-option>
                        <el-option :label="$t('MyFollowers_order_buy')" :value="1"></el-option>
    
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="drawerCardItem">
                      <span class="drawerCard_Label drawerCard_LabelWidth">{{
                        $t("MyFollowers_PL")
                      }}</span>
                      <el-select class="drawerCard_100Input" v-model="followForm.profitType">
                        <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                        <el-option :label="$t('MyFollowers_pl_sell')" :value="1"></el-option>
                        <el-option :label="$t('MyFollowers_pl_buy')" :value="2"></el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="drawerCardBtns">
                      <el-button class="drawerCardBtn" @click="closeOrder()">{{
                        $t("MyFollowers_ClosePosition")
                      }}</el-button>
                    </div>
                  </el-form-item>
                </div>
              </el-form-item>
            </div>
        </el-form>
        <div class="drawerBtns" v-if="checkInviteDetailAccount">
            <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followSubmit(1)">{{
              $t("MyFollowers_confirm") }}</el-button>
  
            <el-button class="cancelDialogBtn" @click.stop="followNext(false)">{{
              $t("MyFollowers_Back")
            }}</el-button>
          </div>
          <div class="drawerBtns" v-if="checkRow.status == 0 && !checkInviteDetailAccount">
            <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followNext(true)">{{
              $t("MyFollowers_AcceptInvitation") }}</el-button>
  
            <el-button class="cancelDialogBtn" @click.stop="isRefuse = true">{{
              $t("MyFollowers_RejectInvitation")
            }}</el-button>
          </div>
        <div class="refuseCard" v-if="isRefuse" ref="refuseCard">
          <p v-if="mobileFlag">
            {{
              $t("MyFollowers_RejectInvitation")
            }}
          </p>
          <el-input type="textarea" :rows="6" :placeholder="$t('MyFollowers_RejectInvitation_DEMO')"
            v-model="refuseReason">
          </el-input>
          <el-button class="confirmDialogBtn" @click="followSubmit(2)">{{
            $t("MyFollowers_Complete")
          }}</el-button>
        </div>
      </div>
      </el-drawer>
      <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
        <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
        <p>
          {{ labelTitle }}
        </p>
        <div >
          <div>
            {{ labelTxt }}
          </div>
          <br />
          <div v-if="labelSubContent">
            {{ labelSubContent }}
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  // @ is an alias to /src
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  import { mapState } from "vuex";
  import CollapseTransition from '@/plugins/utils/collapse-transition';
  import PopupTips from '@/components/moudule/PopupTips.vue'
  import { concatParams, concatTableData } from "../../plugins/common.js";
  import Bus from '../../plugins/bus'


  export default {
    name: "HomeIndex",
    components: {
      'collapse-transition': CollapseTransition,
      PopupTips
    },
    computed: {
      ...mapState(["checkPortfolio", "userInfo", "token", 'mobileFlag']),
    },
    data() {
      var checkMt4 = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("MyFollowers_checkMt4Account")));
        }else if (!Number.isInteger((Number(value)))) {
          callback(new Error(this.$t("MyFollowers_checkMt4AccountNum")));
        } else {
            callback();
        }

      };
      return {
        followData:{},
        signalData:{},
        isOpen:false,
        isOpenFollow:false,
        isSignal:false,
        isFollower:false,
        isSignalAll:false,
        isFollowerAll:false,
        inviteTable:[],
        checkInviteDrawer: false,
      fullscreenLoading: false,
      followForm: {
        account: "",
        following: 0,
        signalMaxDrawdown: 0,
        takeProfit: 0,
        maxDrawdown: 0,
        maxVolume: 0,
        symbols: "",
        autoRecon: true,
        activation: true,
        maxDrawdownStopFollow: false,
        takeProfitStopFollow: false,
        orderType: 0,
        profitType: 0,
        cwPassword: '',
        serverId: '',
          brokerName:1
      },
      followRule: {
        account: [
          // {
          //   required: true,
          //   message: this.$t("MyFollowers_checkMt4Account"),
          //   trigger: "blur",
          // },
          { validator: checkMt4, trigger: 'change' }
        ],
        cwPassword: [
          { required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull'), trigger: 'blur' },
          // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
        ],
        serverId: [
            {
              required: true,
              message: this.$t("serverNotNull"),
              trigger: "blur",
            },
          ],
          cwPassword: [
            { required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull'), trigger: 'blur' },
            // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
          ],
      },
      checkRow: {},
      refuseReason: "",
      isRefuse: false,
      isFollowing: false,
      followDrawerTitle: "",
      activeName: "follow",
      historyTableData: [],
      labelTitle: '',
      labelTxt: '',
      isLableTips: false,
      accountList: [],
      isPopupVisible: false,
      iconImg:require('../../assets/images/NotifyWarning.svg'),
      isStopPopupVisible:false,
      isClosePopupVisible:false,
      btnType:1,
      popupTitle:'',
      popupContent:'',
      clickType:'',
      cancelTxt:'',
      confirmTxt:'',
      isShowClose:false,
      totalProfit:0,
      totalVolume:0,
      brokerList:[],
      serverList:[],
      labelSubContent:'',
      activeHome:'',
      goRegUrl:'',
      checkInviteParameter: true,
            checkInviteDetailAccount: true
      };
    },

    mounted(){
    },
    created() {
      this.getUserType()
      this.getSymbolInfo()
      this.getInviteTable()
      // this.getAccountList()
      this.getServeList()

    },
    methods: {
      formatNumberFractionDigits,
      showCollapse(){},
      tabletoggle(){
        this.isOpen = !this.isOpen
      },
      tabletoggleFollow(){
        this.isOpenFollow = !this.isOpenFollow
      },
      getServeList(){
        this.$axios.get("/Api/invite/BrokerList").then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            // this.mainAccountDrawer = true
            this.brokerList = dt.result;
            this.goRegUrl = dt.result[0].registUrl
            this.serverList = dt.result[0].serverList

            // this.signalForm.portfolioName = dt.result.portfolioName
            // this.tableData = concatTableData(dt.result,10)
          }
        });
      },
      getUserType(){
        this.$axios
        .get("/Api/home/usertype")
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            if(!this.mobileFlag){
              this.isFollowerAll = dt.result.isFollower
              this.isSignalAll = dt.result.isSignal
            }else{
              this.isFollower = dt.result.isFollower
              this.isSignal = dt.result.isSignal
              if(this.isSignal){
                this.activeHome = 'signal'
                this.isSignalAll = true
              }else{
                this.activeHome = 'follower'
                this.isFollowerAll = true

              }
            }



          } else {
            this.$notify.warning({
              message:dt.result,
              title:this.$t('MyFollowers_prompt')
            });
          }
        })
      },
      getSymbolInfo(){
        this.$axios
        .get("/Api/home/index")
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.followData = dt.result.followData
            this.signalData = dt.result.signalData

          } else {
            this.$notify.error(dt.result);
          }
        })
      },
      getInviteTable(){
        this.inviteTable = [];
        this.$axios.get("/Api/Invite/List?status=0").then((res) => {
          let dt = res.data;

          if (dt.isSuccess) {
            this.inviteTable = dt.result.pageList

          }
        });
      },
      // 查看邀请
      checkFollow(row) {
        this.isFollowing = false;
        this.isRefuse = false;
        this.checkRow = row;
        this.checkInviteParameter = true
        this.checkInviteDetailAccount = this.checkRow.status == 0 ? false : true 
        this.followDrawerTitle = this.$t("MyFollowers_ViewInvitation");
        // this.checkInviteDrawer = true
        var params = {
          id: row.id,
        };
        this.$axios
          .get("/Api/Invite/GetInvite" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.checkInviteDrawer = true;
              this.$nextTick(function () {
                this.followForm = Object.assign(this.followForm, dt.result);
                this.followForm.forexSelectLabel = this.followForm.forexSelect
                  ? this.$t("MyFollowers_Fx")
                  : "";
                this.followForm.energySelectLabel = this.followForm.energySelect
                  ? this.$t("MyFollowers_Energy")
                  : "";
                this.followForm.metalsSelectLabel = this.followForm.metalsSelect
                  ? this.$t("MyFollowers_Metal")
                  : "";
                this.followForm.indicesSelectLabel = this.followForm.indicesSelect
                  ? this.$t("MyFollowers_Indices")
                  : "";
                this.followForm.cryptoSelectLabel = this.followForm.cryptoSelect
                  ? this.$t("MyFollowers_Crypto")
                  : "";
                this.followForm.symbols =
                  this.followForm.forexSelectLabel +
                  " " +
                  this.followForm.energySelectLabel +
                  " " +
                  this.followForm.metalsSelectLabel +
                  " " +
                  this.followForm.indicesSelectLabel +
                  " " +
                  this.followForm.cryptoSelectLabel;
                  this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
              });
              // console.log(this.followForm)
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
        // this.$alert(this.$t('checkFollowBefore'), this.$t("MyFollowers_prompt"),{
        //   confirmButtonText: this.$t('MyFollowers_confirm'),
        //   customClass:'alertClass',
        //   callback: action => {

        //   }
        // });

      },
      followNext(status){
        this.checkInviteParameter = status? false : true
        this.checkInviteDetailAccount =  status?  true :false
      },
      followSubmit(status) {
        if (status == 1) {
          this.$refs.followForm.validate((valid) => {
            if (valid) {
              // console.log(111111111111)
              this.btnType = 1
              this.clickType = 'noChange'
              this.popupTitle = 'noChangePassword'
              this.popupContent  = 'noChangePasswordContent'
              this.confirmTxt = 'MyFollowers_confirm'
              this.isShowClose = true
              this.isPopupVisible = true
            }
          });
        } else {
          this.fullscreenLoading = true;
          var params = {
            id: this.checkRow.id,
            // maxDrawdown: this.followForm.maxDrawdown,
            // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
            // takeProfit: this.followForm.takeProfit,
            // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
            status: status,
            refuseReason: this.refuseReason,
          };
          this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getInviteTable()
              this.$refs.followForm.resetFields();
              this.checkInviteDrawer = false;
              this.refuseReason = ""
              Bus.$emit('isInviteRefresh', true);
              //   this.getStatusSignalIR();
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() => {
            this.fullscreenLoading = false;
          });

        }
      },
      //同意邀请
    allowSubmit(){
      this.fullscreenLoading = true;
            var params = {
              id: this.checkRow.id,
              account: this.followForm.account,
              cwPassword: this.followForm.cwPassword,
              // maxDrawdown: this.followForm.maxDrawdown
              //   ? this.followForm.maxDrawdown
              //   : 0,
              // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
              // takeProfit: this.followForm.takeProfit
              //   ? this.followForm.takeProfit
              //   : 0,
              // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
              serverId:this.followForm.serverId,
              status: 1,
            };
            this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
              let dt = res.data;
              if (dt.isSuccess) {

                this.getInviteTable()
                this.getSymbolInfo()
                this.$notify.success({title: this.$t('nofity_success'), message:dt.result});
                this.$refs.followForm.resetFields();
                this.checkInviteDrawer = false;
                Bus.$emit('isInviteRefresh', true);

              } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
              }
            }).finally(() => {
              this.fullscreenLoading = false;
            });
    },
    cancelClosePopup(val){
      this.isPopupVisible = val
      this.cancelTxt = 'MyFollowers_Cancel'
      this.confirmTxt = 'MyFollowers_confirm'
      this.isShowClose = false
    },
      // getAccountList() {
      //   this.$axios.get("/Api/MainAccount/GetMainAccountList").then((res) => {
      //     let dt = res.data;
      //     if (dt.isSuccess) {
      //       this.mainAccountDrawer = true
      //       this.accountList = dt.result;
      //       this.signalForm.portfolioName = dt.result.portfolioName
      //        this.tableData = concatTableData(dt.result,10)
      //     }
      //   });
      // },
      // mt4选择
      selectChange(val) {
        // console.log(val)
        this.brokerList.forEach((item,index) =>{
          if(item.id == val){
            this.serverList = item.serverList
          }
        })

      },
      closeDrawer() {
        this.$refs.followForm.resetFields();
        this.checkInviteDrawer = false;
      },
      cancelPopup(val){
          this.isPopupVisible = val
          this.cancelTxt = 'MyFollowers_Cancel'
          this.confirmTxt = 'MyFollowers_confirm'
          this.isShowClose = false
          if(this.clickType == 'stopCopy'){
            this.cancelStopPopup()
          }
      },
      confirmPopup(val){
        this.isPopupVisible = val
        this.cancelTxt = 'MyFollowers_Cancel'
        this.confirmTxt = 'MyFollowers_confirm'
        this.isShowClose = false
          this.allowSubmit()
      },
      toFollowAll(status){
        this.$router.push({ path: '/FollowSymbol',query:{status:status} });
      },
      toEcharts(account){
        this.$router.push({ path: '/FollowEcharts',query:{account:account} });
      },
      toFollowList(status){
        if(status == 0){
          this.$router.push({ path: '/FollowInvited',query:{status:status} });

        }else{
          this.$router.push({ path: '/MyFollow',query:{status:status} });

        }
      },
      toAccount(row) {
        this.$store.commit('setPortfolio', row)
        this.$store.commit('setChooseSignal', row.portfolioName)
        this.$store.commit('setActiveTab', 'signalBaseInfo')
        this.$router.push({ path: '/MyAccount' })
      },
      labelIconClick(title, content, subContent) {

        if (this.mobileFlag) {
          this.isLableTips = !this.isLableTips;
          this.labelTitle = title;
          this.labelTxt = content;
          this.labelSubContent = subContent
        } else {
          this.isLableTips = false;
        }
      },
      screenClick() {
            // debugger
            document.addEventListener("click", (e) => {
              if (this.$refs.mobileLabelTips) {
                let self = this.$refs.mobileLabelTips.contains(e.target);
                if (!self) {
                  this.isLableTips = false;
                  this.labelSubContent = ''

                }
              }

            });
      },
      handleClick(tab){
        console.log(tab)
        if(tab.name == 'signal'){
          this.isSignalAll = true
          this.isFollowerAll = false
        }else{
          this.isFollowerAll = true
          this.isSignalAll = false
        }
      },
      toReg(){
          window.open('https://crm.ecmarkets.co/zh-CN/sign/register')
        }
    },
  };
  </script>
  <style lang="less" scoped>
  .homeTotalUl{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    li{
      cursor: pointer;
      list-style: none;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-width: 226px;
      min-height: 120px;
      border-radius: 10px;
      background: #fff;
      justify-content: center;
      margin-right: 20px;
      .homeTotalNum{
        color: #47A92A;
        text-align: center;
        font-family: 'PingFangSC-Semibold';
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .homeTotalNum.red{
        color: #4D71FF;
      }
      .homeTotalNum.orange{
        color: #FFC246;
      }
      .homeTotalStatus{
        color:  #212121;
        text-align: center;
        font-family: 'PingFangSC-Medium';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 9px;
      }

    }

  }
  .tableDiv{
    padding: 0!important;
  }
  .pageSubtitle{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #0B205D;
    font-family: 'PingFangSC-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .pageSubIcon{
      width: 6px;
      height: 6px;
      transform: rotate(-135deg);
      border-radius: 1px;
      opacity: 0.45;
      background: #0B205D;
      display: inline-block;
      margin-right: 6px;
    }
  }
  .PageCommon{
    .pageTitle{
      color: #0B205D;
      font-family: 'PingFangSC-Semibold';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .greenNum{
        color: #47A92A;
      }
      .redNum{
        color: #E35972;
      }
      .mt4Click{
        cursor: pointer;
        color: #3b9eee;
      }
      .homeNumColorblue{
        color: #4D71FF!important;
      }
      .homeNumColorgreen{
        color: #74C0D0!important;
      }
      .homeNumColorgrey{
        color: #83878B!important;
      }
      .homeNumColorpurple{
        color: #868CF3!important;
      }
  @media screen and (max-width:767px){
    .HomeIndex{
      background: #F4F4F4;
      width: 100%;
      overflow-y: scroll;
      .pageSubtitle{
        margin: 15px 0 10px 0;
      }
      .homeTotalUl{
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
        li{
          width: 47%;
          min-width: 0;
          margin-right: 0;
          min-height: 83px;
          margin-bottom: 14px;
          .homeTotalNum{
            font-size: 24px;
          }
          .homeTotalStatus{
            color: #0B205D;
            font-size: 14px;
          }
        }
      }
    }
  }
  </style>
